import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import Highlight, { defaultProps, theme } from 'prism-react-renderer'
import github from 'prism-react-renderer/themes/github'

import 'katex/dist/katex.min.css';

import Prism from 'prism-react-renderer/prism'
window.Prism = Prism

require("prismjs/themes/prism-solarizedlight.css")

require("prismjs/components/prism-csharp")
require("prismjs/components/prism-solidity")
require("prismjs/components/prism-sql")
require("prismjs/components/prism-hcl")
require("prismjs/components/prism-rust")
require("prismjs/components/prism-bash")
require("prismjs/components/prism-toml")

/* eslint-disable */
const component = {
  pre: (props) => {
    const className = props.children.props.className || ''
    const matches = className.match(/language-(?<lang>.*)/)
    return (
      <Highlight
        {...defaultProps}
        code={props.children.props.children}
        language={matches && matches.groups && matches.groups.lang ? matches.groups.lang : ''}
        theme={github}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    )
  },
}
export const wrapRootElement = ({ element }) => {
  return <MDXProvider components={component}>{element}</MDXProvider>
}
